<template>
  <section class="section">
    <div v-if="api === null">
      <form v-on:submit.prevent="login()">
        <div class="field">
          <label for="" class="label">Username</label>
          <div class="control has-icons-left">
            <input
              type="text"
              class="input"
              v-model="credentials.username"
              required
            />
            <span class="icon is-small is-left">
              <i class="fa fa-envelope"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label for="" class="label">Password</label>
          <div class="control has-icons-left">
            <input
              type="password"
              v-model="credentials.password"
              placeholder="*******"
              class="input"
              required
            />
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <button class="button is-success">Login</button>
        </div>
      </form>
    </div>
    <div v-else>
      <div class="level">
        <div class="level-left">
          <h2 class="title is-4">Admin</h2>
        </div>
        <div class="field level-right">
          <div class="control ml-5">
            <button class="button is-warning" v-on:click="logout()">
              Logout
            </button>
          </div>
        </div>
      </div>
      <h2 class="title is-4">Connectors</h2>
        <b-table
          :data="connectors"
          :paginated="true"
          :per-page="25"
          >
          <b-table-column field="@id" label="IDS ID" v-slot="props">
            {{ props.row['@id'] }}
          </b-table-column>
          <b-table-column field="ids:curator" label="Curator" v-slot="props">
            {{ props.row['ids:curator'] }}
          </b-table-column>
          <b-table-column field="ids:maintainer" label="Maintainer" v-slot="props">
            {{ props.row['ids:maintainer'] }}
          </b-table-column>
          <b-table-column field="ids:title" label="Titles" v-slot="props">
            <span v-for="(title, idx) in props.row['ids:title']" :key="props.row['@id']+'title'+idx">
              {{ title['@value'] }}
              <template v-if="title['@language']">(<span class="has-text-weight-bold">{{ title['@language'] }}</span>)</template>
              <hr style="margin: 0.5rem"/>
            </span>
          </b-table-column>
          <b-table-column field="ids:description" label="Description" v-slot="props">
            <span v-for="(title, idx) in props.row['ids:description']" :key="props.row['@id']+'description'+idx">
              {{ title['@value'] }}
              <template v-if="title['@language']">(<span class="has-text-weight-bold">{{ title['@language'] }}</span>)</template>
              <hr style="margin: 0.5rem"/>
            </span>
          </b-table-column>
          <b-table-column label="Remove" v-slot="props">
            <button class="button is-danger is-size-7 is-outlined" @click="deleteConnector(props.row['@id'])">
                Remove
            </button>
          </b-table-column>
        </b-table>
        <h2 class="title is-4">Participants</h2>
        <b-table
          :data="participants"
          :paginated="true"
          :per-page="25"
          >
          <b-table-column field="@id" label="IDS ID" v-slot="props">
            {{ props.row['@id'] }}
          </b-table-column>
          <b-table-column field="ids:curator" label="Contact" v-slot="props">
            {{ props.row['ids:memberPerson'][0]['ids:givenName'] }} {{ props.row['ids:memberPerson'][0]['ids:familyName'] }}
          </b-table-column>
          <b-table-column field="ids:title" label="Titles" v-slot="props">
            <span v-for="(title, idx) in props.row['ids:title']" :key="props.row['@id']+'title'+idx">
              {{ title['@value'] }}
              <template v-if="title['@language']">(<span class="has-text-weight-bold">{{ title['@language'] }}</span>)</template>
              <hr style="margin: 0.5rem"/>
            </span>
          </b-table-column>
          <b-table-column field="ids:description" label="Description" v-slot="props">
            <span v-for="(description, idx) in props.row['ids:description']" :key="props.row['@id']+'description'+idx">
              {{ description['@value'].substring(0,200) }}
              <template v-if="description['@language']">(<span class="has-text-weight-bold">{{ description['@language'] }}</span>)</template>
              <hr style="margin: 0.5rem"/>
            </span>
          </b-table-column>
          <b-table-column label="Remove" v-slot="props">
            <button class="button is-warning is-size-7 is-outlined"
                @click="editParticipant(props.row)">Edit
            </button>
            <button class="button is-danger is-size-7 is-outlined"
                @click="deleteParticipant(props.row['@id'])">Remove
            </button>
          </b-table-column>
        </b-table>
      <h2 class="title is-4">New participant</h2>
      <form @submit.prevent="createParticipant">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Participant IDS ID</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.id"
                  type="text"
                  placeholder="urn:scsn:ids:participants:SCSN-Foundation"
                />
              </div>
            </div>
            <p class="help">IDS Identifier</p>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Participant Title</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant['title']"
                  type="text"
                  placeholder="Netherlands Organisation for Applied Scientific Research"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Legal Form</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="select">
                  <select v-model="newParticipant.legalForm" aria-placeholder="Other">
                    <option value="">Other</option>
                    <option value="54M6">Besloten vennootschap met beperkte aansprakelijkheid</option>
                    <option value="33MN">Vereniging</option>
                    <option value="4QXM">Eenmanszaak</option>
                    <option value="62Y3">Vennootschap onder firma</option>
                    <option value="B5PM">Naamloze vennootschap</option>
                    <option value="NFFH">Coöperatie</option>
                    <option value="V44D">Stichting</option>
                  </select>
                </div>
                
                <div class="control" v-if="!['54M6','33MN','4QXM','62Y3','B5PM','NFFH','V44D'].includes(newParticipant.legalForm)">
                  <input
                    class="input"
                    v-model="newParticipant.legalForm"
                    type="text"
                    placeholder="ELF-Code and ISO-20275 Legal form code"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Contact</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.contact.givenName"
                  type="text"
                  placeholder="Given name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal"></div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.contact.familyName"
                  type="text"
                  placeholder="Family name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal"></div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.contact.emailAddress"
                  type="text"
                  placeholder="Email address"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal"></div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.contact.phoneNumber"
                  type="text"
                  placeholder="Phone number"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Corporate homepage</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.corporateHomepage"
                  type="text"
                  placeholder="https://tno.nl"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Corporate email address</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.corporateEmailAddress"
                  type="text"
                  placeholder="info@tno.nl"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Primary site</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.primarySite"
                  type="text"
                  placeholder="Anna van Buerenplein 1, 2595 DA Den Haag"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Logo (URL)</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input
                  class="input"
                  v-model="newParticipant.logo"
                  type="text"
                  placeholder="https://smart-connected.nl/_theme/scsn/images/frontend/scsn-logo.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal"></div>
          <div class="field-body">
            <div class="control">
              <button class="button is-link">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import vSelect from "vue-select";

export default {
  props: {
    credentials: {
      type: Object,
      required: false,
    },
  },
  components: {
    // vSelect,
  },
  data() {
    return {
      connectors: [],
      participants: [],
      api: null,
      provision: {
        disabled: false,
        gln: "",
        brn: "",
        vat: "",
        spid: "",
      },
      newParticipant: {
        id: "",
        title: "",
        legalForm: "54M6",
        contact: {
          givenName: "",
          familyName: "",
          emailAddress: "",
          phoneNumber: "",
        },
        logo: "",
        corporateHomepage: "",
        corporateEmailAddress: "",
        primarySite: "",
      },
    };
  },
  created: async function () {
    if (this.credentials.username != "" && this.credentials.password != "") {
      this.createApi();
    }
  },
  methods: {
    login: async function () {
      this.createApi();
    },
    logout: function () {
      this.credentials.username = "";
      this.credentials.password = "";
      this.api = null;
      this.$emit("credentialChange", {
        username: this.credentials.username,
        password: this.credentials.password,
      });
    },
    createApi: async function () {
      const axiosOptions = {
        baseURL:
          typeof webpackHotUpdate !== "undefined"
            ? "https://broker.beta.ids.smart-connected.nl/api/admin/"
            : "/api/admin/",
        timeout: 5000,
        auth: {
          username: this.credentials.username,
          password: this.credentials.password,
        },
      };
      console.log(axiosOptions);
      this.api = axios.create(axiosOptions);
      await this.getConnectors();
      await this.getParticipants();
      this.$emit("credentialChange", {
        username: this.credentials.username,
        password: this.credentials.password,
      });
    },
    getConnectors: async function () {
      const connectors = await this.api.get("connectors", {
        auth: {
          username: this.credentials.username,
          password: this.credentials.password,
        },
      });
      this.connectors = connectors.data;
    },
    getParticipants: async function () {
      const participants = await this.api.get("participants", {
        auth: {
          username: this.credentials.username,
          password: this.credentials.password,
        },
      });
      this.participants = participants.data;
    },
    createParticipant: async function () {
      await this.api.put(
        `participants/${btoa(this.newParticipant.id)}`,
        this.newParticipant
      );
      this.newParticipant = {
        id: "",
        title: "",
        legalForm: "54M6",
        contact: {
          givenName: "",
          familyName: "",
          emailAddress: "",
          phoneNumber: "",
        },
        logo: "",
        corporateHomepage: "",
        corporateEmailAddress: "",
        primarySite: "",
      };
      await this.getParticipants();
    },
    deleteConnector: async function (connectorId) {
      await this.api.delete(`connectors/${btoa(connectorId)}`);
      this.fetchConnectors();
    },
    deleteParticipant: async function (participantId) {
      await this.api.delete(`participants/${btoa(participantId)}`);
      this.fetchParticipants();
    },
    editParticipant: function (participant) {
      const logo = (participant['ids:description'] !== null) ? participant['ids:description'].filter(d => d['@language'] === 'logo')[0] || {'@value': ''} : {'@value': ''};
      this.newParticipant = {
          id: participant['@id'],
          title: participant['ids:title'][0]['@value'],
          legalForm: participant['ids:legalForm'],
          contact: {
              givenName: participant['ids:memberPerson'][0]['ids:givenName'],
              familyName: participant['ids:memberPerson'][0]['ids:familyName'],
              emailAddress: participant['ids:memberPerson'][0]['ids:emailAddress'][0],
              phoneNumber: participant['ids:memberPerson'][0]['ids:phoneNumber'][0]
          },
          logo: logo['@value'],
          corporateHomepage: participant['ids:corporateHomepage'],
          corporateEmailAddress: participant['ids:corporateEmailAddress'][0],
          primarySite: participant['ids:primarySite']['ids:siteAddress']
      }
    },

  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>